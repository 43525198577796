export function parseSVGContentFromMaskImage(maskImage: string): string {
  const svgContent = maskImage.match(/.*svg\+xml,(.*)"\)$/);

  if (svgContent && svgContent[1]) {
    return window.decodeURIComponent(svgContent[1]);
  }

  return '';
}

export function getSvgImageWithMask({
  svgContent,
  compId,
  alt,
}: {
  svgContent: string;
  compId: string;
  alt: string;
}): string {
  const svgOpenTagMatch = svgContent.match(/(:?<\?xml[^?]*\?>)?(<svg[^>]*>)/);

  if (!svgOpenTagMatch) {
    return '';
  }

  // remove the <?xml ...?> part
  const svgString = svgOpenTagMatch[1]
    ? svgContent.replace(svgOpenTagMatch[1], '')
    : svgContent;
  const dummy = window.document.createElement('div');
  dummy.innerHTML = svgString;
  const svg = dummy.firstElementChild;

  if (!svg) {
    return '';
  }

  const svgContentId = `svg-content-${compId}`;

  svg.setAttribute('id', svgContentId);

  const filterId = `${svgContentId}-filter`;
  const maskId = `${svgContentId}-mask`;

  return `<svg ref="svg${compId}" id="svg${compId}" version="1.1" preserveAspectRatio="none" width="100%" height="100%" role="img" aria-label="${alt}">
      <defs>
        <filter id="${filterId}">
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1
                    0 0 0 0 1
                    0 0 0 0 1
                    0 0 0 1 0"
          />
        </filter>
        <mask id="${maskId}">
          <use
            filter="url(#${filterId})"
            xlink:href="#${svgContentId}"
            width="100%"
            height="100%"
            x="0"
            y="0"
          />
        </mask>
        ${dummy.innerHTML}
      </defs>
      <image id="${compId}image" ref="image"  mask="url(#${maskId})" width="100%" height="100%"/>
    </svg>`;
}
